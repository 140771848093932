import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  constructor(private dataServise: DataService) { }

  async listar() {
    const url = 'contacto/listarContacto.php';
    const res = await this.dataServise.metodoGet(url);
    return res;
  }
}

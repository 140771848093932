import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-organigrama',
	templateUrl: './organigrama.component.html',
	styleUrls: ['./organigrama.component.css']
})

export class OrganigramaComponent implements OnInit {

	myThumbImage = "/assets/images/img-empresa/organigrama.jpg";
  	myFullImage = "/assets/images/img-empresa/organigrama.jpg";

  	constructor(private route: Router) { }

  	ngOnInit() {}

  	goToUrl(url) {
		this.route.navigate([url]);
  	}
}

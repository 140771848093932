import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/services/contacto.service';
import { ComentarioService } from '../../services/comentario.service';
import { Router } from '@angular/router';

/* declare var $: any;
declare global {
  interface JQuery {
    (selector: string): JQuery;
    powerTip(options?: any): any;
    modal(String): JQuery;
    hide(): JQuery;
    fadeIn(): JQuery;
    fadeOut(): JQuery;
    show(): JQuery;
    removeClass(String): JQuery;
    hasClass(String): JQuery;
    addClass(String): JQuery;
    css(a: String, b: String): JQuery;
    data(a: String, b: JQuery): JQuery;
    DataTable(options?: any): any;
  }
} */
@Component({
  selector: 'app-home-back',
  templateUrl: './home-back.component.html',
  styleUrls: ['./home-back.component.css']
})
export class HomeBackComponent implements OnInit {

  arrayContacto = [];
  auxArrayContacto = [];
  auxArrayContacto2 = [];
  indice1 = 0;
  arrayPaginacion1 = [];
  arrayComentario = [];
  auxArrayComentario = [];
  auxArrayComentario2 = [];
  indice2 = 0;
  arrayPaginacion2 = [];
  dataContacto = {
    nombre: '',
    email: '',
    telefono: '',
    club: '',
    fecha: ''
  };
  dataComentario = {
    titulo1: '',
    titulo2: '',
    descripcion: ''
  };

  constructor(
    private Contacto: ContactoService,
    private Comentario: ComentarioService,
    private router: Router
  ) { }

  ngOnInit() {
    $('footer').hide();
    $('header').hide();
    this.cargarContacto();
    this.cargarComentario();
  }

  cargarContacto() {
    this.Contacto.listar().then(value => value.subscribe(res => {
      // tslint:disable-next-line: no-string-literal
      if (res['status'] === 'success') {
        // tslint:disable-next-line: no-string-literal
        this.arrayContacto = res['data'];
        this.auxArrayContacto2 = res['data'];
        this.pagination(this.arrayContacto, this.arrayPaginacion1, this.indice1, 1);
      }
    }, error => {
      console.log(error);
    }));
  }

  cargarComentario() {
    this.Comentario.listar().then(value => value.subscribe(res => {
      // tslint:disable-next-line: no-string-literal
      if (res['status'] === 'success') {
        // tslint:disable-next-line: no-string-literal
        this.arrayComentario = res['data'];
        this.auxArrayComentario2 = res['data'];
        this.pagination(this.arrayComentario, this.arrayPaginacion2, this.indice2, 2);
      }
    }, error => {
      console.log(error);
    }));
  }

  tab(btn, contc) {
    $('.nav-link').removeClass('active');
    $('.tab-pane').removeClass('show active');
    $('#' + btn).addClass('active');
    $('#' + contc).addClass('show active');
  }

  pagination(array, arrayP, indixe, option) {
    let fin = 10;
    let inicio = 0;
    const total = Math.ceil(array.length / 10);
    for (let index = 0; index < total; index++) {
      arrayP.push({
        inicio,
        fin,
      });
      inicio = fin;
      fin += 10;
    }
    const data = total === 0 ? {inicio, fin} : arrayP[0];
    this.selecion(data, indixe, option);
  }

  sumar(indice, array, option) {
    if (indice < array.length) {
      indice += 1;
    }
    indice = indice === array.length ? array.length - 1 : indice;
    this.selecion(array[indice], indice, option);
  }

  restar(indice, array, option) {
    if (indice > 0) {
      indice -= 1;
    }
    indice = indice - 1 === -1 ? 0 : indice;
    this.selecion(array[indice], indice, option);
  }

  selecion(data, indice, option) {
    if (option === 1) {
      this.indice1 = indice;
      this.auxArrayContacto = this.auxArrayContacto2.slice(data.inicio, data.fin);
    } else if (option === 2) {
      this.indice2 = indice;
      this.auxArrayComentario = this.auxArrayComentario2.slice(data.inicio, data.fin);
    }
  }

  buscar(event, indice) {
    const text = event.target.value.toUpperCase();
    if (indice === 1) {
      this.arrayPaginacion1 = [];
      if (text !== '') {
        this.auxArrayContacto2 = this.arrayContacto.filter(f => f.nombre.toUpperCase().indexOf(text) > -1 || f.email.toUpperCase().indexOf(text) > -1 || f.fecha.toUpperCase().indexOf(text) > -1);
      } else {
        this.auxArrayContacto2 = this.arrayContacto;
      }
      this.pagination(this.auxArrayContacto2, this.arrayPaginacion1, this.indice1, 1);
    } else if (indice === 2) {
      this.arrayPaginacion2 = [];
      if (text !== '') {
        this.auxArrayComentario2 = this.arrayComentario.filter(f => f.titulo1.toUpperCase().indexOf(text) > -1 || f.titulo2.toUpperCase().indexOf(text) > -1 || f.descripcion.toUpperCase().indexOf(text) > -1);
      } else {
        this.auxArrayComentario2 = this.arrayComentario;
      }
      this.pagination(this.auxArrayComentario2, this.arrayPaginacion2, this.indice2, 2);
    }
  }

  verDetalle(item, indice) {
    if (indice === 1) {
      this.dataContacto = item;
      $('#contactoModal').modal('show');
    } else if (indice === 2) {
      this.dataComentario = item;
      $('#comentarioModal').modal('show');
    }
  }

  cerrar() {
    localStorage.removeItem('dataUser');
    this.router.navigate(['./login']);
  }

}

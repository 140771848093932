import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $ :any;

declare global {
	interface JQuery {
	  (selector: string): JQuery;
	  powerTip(options?: any): any;
	  modal(String): JQuery;
	  hide():  JQuery;
	  fadeIn(): JQuery; 
	  fadeOut(): JQuery;
	  show(): JQuery;
	  removeClass(String): JQuery;
	  hasClass(String): JQuery;
	  addClass(String): JQuery;
	  css(a: String, b: String): JQuery;
	  data(a: String, b: JQuery): JQuery;
	}
  }

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	collapsed = true;
  	collapsed2 = true;
  	active;

  	constructor(private route: Router) {}

  	ngOnInit() {
		var url = window.location.pathname;

		if(url == '/home') {
			this.active = 1;
		} else if(url == '/promocionInmobiliaria') {
			this.active = 2;
		} else if(url == '/construccionEdificaciones') {
			this.active = 2;
		} else if(url == '/gerenciaproyecto') {
			this.active = 2;
		} else if(url == '/misionvision') {
			this.active = 4;
		} else if(url == '/contacto') {
			this.active = 5;
		} else {
		this.active = 1;
		}
  	}
	  
	toggleCollapsed(): void {
		this.collapsed = !this.collapsed;
	}

	toggleCollapsed2(): void {
		this.collapsed2 = !this.collapsed2;
	}

  	goToUrl(url) {
    	if(url == './home') {
      		this.active = 1;
    	} else if(url == './promocionInmobiliaria') {
      		this.active = 2;
    	} else if(url == './construccionEdificaciones') {
      		this.active = 2;
    	} else if(url == './gerenciaproyecto') {
      		this.active = 2;
    	} else if(url == './misionvision') {
      		this.active = 4;
    	} else if(url == './contacto') {
      		this.active = 5;
    	}

		this.route.navigate([url]);
	  }

	 redirect(){
		var posicion = $("#Proyectourl").offset().top;
		$("html, body").animate({
			scrollTop: posicion
		}, 2000);   
	 }

}

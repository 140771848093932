import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ToastrModule, ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OwlModule } from 'ngx-owl-carousel';
import { HomeComponent } from './pages/home/home.component';
import { PromocioninmobiliariaComponent } from './pages/promocioninmobiliaria/promocioninmobiliaria.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ConstruccionedificacionesComponent } from './pages/construccionedificaciones/construccionedificaciones.component';
import { GerenciaproyectoComponent } from './pages/gerenciaproyecto/gerenciaproyecto.component';
import { ConoceempresaComponent } from './pages/conoceempresa/conoceempresa.component';
import { MisionvisionComponent } from './pages/misionvision/misionvision.component';
import { OrganigramaComponent } from './pages/organigrama/organigrama.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { PoliticaComponent } from './pages/politica/politica.component';
import { CompromisoComponent } from './pages/compromiso/compromiso.component';
import { TrusturlPipe } from './pipes/trusturl.pipe';
import { LoginComponent } from './pages/login/login.component';
import { HomeBackComponent } from './pages/home-back/home-back.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		PromocioninmobiliariaComponent,
		HeaderComponent,
		FooterComponent,
		ConstruccionedificacionesComponent,
		GerenciaproyectoComponent,
		ConoceempresaComponent,
		MisionvisionComponent,
		OrganigramaComponent,
		ContactoComponent,
		PoliticaComponent,
		CompromisoComponent,
		TrusturlPipe,
		LoginComponent,
		HomeBackComponent,
  	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgbModule,
		OwlModule,
		HttpClientModule,
		FormsModule,
		ToastNoAnimationModule.forRoot(),
		DataTablesModule
	],
  	providers: [HomeComponent],
  	bootstrap: [AppComponent]
})

export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /* private api = 'http://localhost/apiC/controllers/'; */
  private api = 'https://grupoinnova7.com/api/controllers/';

  constructor(private http: HttpClient) { }

  metodoPost(url, data) {
    return this.http.post(this.api + '' + url, data);
  }

  metodoGet(url) {
    return this.http.get(this.api + '' + url);
  }
}

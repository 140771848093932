import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-conoceempresa',
  	templateUrl: './conoceempresa.component.html',
  	styleUrls: ['./conoceempresa.component.css']
})

export class ConoceempresaComponent implements OnInit {

  constructor(private route: Router) { }

  	ngOnInit() {}

  	goToUrl(url) {
		this.route.navigate([url]);
  	}
}

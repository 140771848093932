import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

declare var $: any;
declare global {
  interface JQuery {
    (selector: string): JQuery;
    powerTip(options?: any): any;
    modal(String): JQuery;
    hide(): JQuery;
    fadeIn(): JQuery;
    fadeOut(): JQuery;
    show(): JQuery;
    removeClass(String): JQuery;
    hasClass(String): JQuery;
    addClass(String): JQuery;
    css(a: String, b: String): JQuery;
    data(a: String, b: JQuery): JQuery;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  dataLogin = {
    email: '',
    password: ''
  };

  constructor(
    private service: ServiceService,
    private User: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    $('footer').hide();
    $('header').hide();
  }

  login() {
    if (this.validar()) {
      this.User.Login(this.dataLogin).then(value => value.subscribe(res => {
        // tslint:disable-next-line: no-string-literal
        if (res['status'] === 'success') {
          // tslint:disable-next-line: no-string-literal
          localStorage.setItem('dataUser', JSON.stringify(res['data']));
          this.router.navigate(['/home-back']);
        }
      }, error => console.log(error)));
    }
  }

  validar() {
    if (!this.service.validarText(this.dataLogin.email)) {
      this.service.Alert('warning', '', 'El correo está vacío', 'Aceptar', '', false);
      return false;
    } else if (!this.service.validarCorreo(this.dataLogin.email)) {
      this.service.Alert('warning', '', 'El correo no tiene el formato pemitido', 'Aceptar', '', false);
      return false;
    } else if (!this.service.validarText(this.dataLogin.password)) {
      this.service.Alert('warning', '', 'La contraseña está vacío', 'Aceptar', '', false);
      return false;
    }
    return true;
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-contacto',
	templateUrl: './contacto.component.html',
	styleUrls: ['./contacto.component.css']
})

export class ContactoComponent implements OnInit {

	name;
	phone;
	city;
	email;
	message;

	constructor(
		private http: HttpClient,
		private toastr: ToastrService
	) {}

	ngOnInit() {}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
		  // A client-side or network error occurred. Handle it accordingly.
		  console.error('An error occurred:', error.error.message);
		} else {
		  // The backend returned an unsuccessful response code.
		  // The response body may contain clues as to what went wrong,
		  console.error(
			`Backend returned code ${error.status}, ` +
			`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(
			'Something bad happened; please try again later.');
	  };

	send() {
		console.log('enter send');
		let name = this.name;
		let phone = this.phone;
		let city = this.city;
		let email = this.email;
		let message = name + ' con el numero de telefono: ' + phone + ' ubicado en la ciudad de ' + city + ' le ha enviado el siguiente mensaje: ' + this.message;
		let data = new FormData();
		data.append('email', email);
		data.append('message', message);

		if (this.message) {
			if ( this.message.length > 5 ) {
				return this.http.post('http://grupoinnova7.com/mail.php', data, {}).pipe().subscribe(data => {
					console.log('data');
					if(data == true) {
						this.toastr.success('Éxito', 'El mensaje fue enviado con éxito');

						this.name = '';
						this.phone = '';
						this.city = '';
						this.email = '';
						this.message = '';
					} else {
						this.toastr.error('Error al enviar el mensaje', 'Error');
					}
				}, error => {
					this.toastr.error("No fue posible enviar el mensaje. Inténtelo más tarde.", 'Error');
				});
			}else{
				this.toastr.error('Debes de agregar más palabras a su mensaje.', 'Error');
			}
		}else{
			this.toastr.error('Debes de agregar más palabras a su mensaje.', 'Error');
		}
	}

}

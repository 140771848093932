import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-gerenciaproyecto',
	templateUrl: './gerenciaproyecto.component.html',
	styleUrls: ['./gerenciaproyecto.component.css']
})

export class GerenciaproyectoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TrusturlPipe } from '../../pipes/trusturl.pipe';

declare var $: any;
declare global {
	interface JQuery {
		(selector: string): JQuery;
		powerTip(options?: any): any;
		modal(String): JQuery;
		hide():  JQuery;
		fadeIn(): JQuery; 
		fadeOut(): JQuery;
		show(): JQuery;
		removeClass(String): JQuery;
		hasClass(String): JQuery;
		addClass(String): JQuery;
		css(a: String, b: String): JQuery;
		data(a: String, b: JQuery): JQuery;
  	}
}

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	providers: [TrusturlPipe]
})

export class HomeComponent implements OnInit {
	modalOptions: NgbModalOptions;
	hidden = true;
	url = 'https://player.vimeo.com/video/374257782';
	closeResult: string;
	data;
	
	constructor(
		private route: Router,
		private modalService: NgbModal,
		private trusturl: TrusturlPipe
	) {
		this.data = this.trusturl.transform(this.url, 'resourceUrl');
		this.modalOptions = {
			backdrop: 'static',
			backdropClass: 'customBackdrop'
		}
	}
	
	ngOnInit() {
		var posicion = $('#header').offset().top;
		console.log(posicion);
		window.scroll(0, 0);
		// $('#exampleModal').modal('show'); //este es modal del inicio lo mandaron a quitar por el momento
	}

	goToUrl(url) {
		this.route.navigate([url]);
	}

	open(content) {
		this.modalService.open(content, this.modalOptions).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	
	openxl(content) {
		this.modalService.open(content,  {ariaLabelledBy: 'Modal-video', size: 'xl'}).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	abrir() {
		this.hidden = false;
	}


	cerrar() {
		this.hidden = true;
	}
	
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return  `with: ${reason}`;
		}
	}
}

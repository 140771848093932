import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-construccionedificaciones',
	templateUrl: './construccionedificaciones.component.html',
  	styleUrls: ['./construccionedificaciones.component.css']
})

export class ConstruccionedificacionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}

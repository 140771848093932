import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-misionvision',
	templateUrl: './misionvision.component.html',
	styleUrls: ['./misionvision.component.css']
})

export class MisionvisionComponent implements OnInit {

  	constructor(private route: Router) { }

  	ngOnInit() {}

  	goToUrl(url) {
		this.route.navigate([url]);
  	}
}

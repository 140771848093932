import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor() { }

  Alert(icon, title, text, confirmButtonText, cancelButtonText, showCancelButton = false) {
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText,
      cancelButtonText,
      showCancelButton
    });
  }

  validarNumero(valor) {
    // tslint:disable-next-line: max-line-length
    if (isNaN(valor) === true || valor === 'NaN' || valor <= 0.000000 || valor === undefined || valor === null || valor === '' || valor === 'null') {
      return false;
    } else {
      return true;
    }
  }

  validarText(valor) {
    if (valor === undefined || valor == null || valor === '' || valor === 'null' || valor <= 0) {
      return false;
    } else {
      return true;
    }
  }

  validarCorreo(email) {
    // tslint:disable-next-line: max-line-length
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(pattern)) {
        return true;
    } else {
        return false;
    }
  }

  ordenar(array, atributo) {
    return array.sort((a, b) => {
      if (a[atributo] > b[atributo]) {
        return 1;
      } else if (a[atributo] < b[atributo]) {
        return -1;
      }
      return 0;
    });
  }

  ordenar_fecha_desendente(array, atributo) {
    return array.sort((a, b) => {
      return b[atributo] - a[atributo];
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-politica',
	templateUrl: './politica.component.html',
  	styleUrls: ['./politica.component.css']
})

export class PoliticaComponent implements OnInit {

  	constructor(private route: Router) { }

  	ngOnInit() {}

  	goToUrl(url) {
		this.route.navigate([url]);
  	}
}

import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private dataServise: DataService) { }

  async save(data) {
    const url = 'user/createUser.php';
    const res = await this.dataServise.metodoPost(url, data);
    return res;
  }

  async Login(data) {
    const url = 'user/login.php';
    const res = await this.dataServise.metodoPost(url, data);
    return res;
  }
}

import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ComentarioService {

  constructor(private dataServise: DataService) { }

  async listar() {
    const url = 'comentario/listarComentario.php';
    const res = await this.dataServise.metodoGet(url);
    return res;
  }
}

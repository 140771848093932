import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PromocioninmobiliariaComponent } from './pages/promocioninmobiliaria/promocioninmobiliaria.component';
import { ConstruccionedificacionesComponent } from './pages/construccionedificaciones/construccionedificaciones.component';
import { GerenciaproyectoComponent } from './pages/gerenciaproyecto/gerenciaproyecto.component';
import { ConoceempresaComponent } from './pages/conoceempresa/conoceempresa.component';
import { MisionvisionComponent } from './pages/misionvision/misionvision.component';
import { OrganigramaComponent } from './pages/organigrama/organigrama.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { PoliticaComponent } from './pages/politica/politica.component';
import { CompromisoComponent } from './pages/compromiso/compromiso.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeBackComponent } from './pages/home-back/home-back.component';

const routes: Routes = [
	{ path: 'home', component: HomeComponent },
	{ path: 'promocionInmobiliaria', component: PromocioninmobiliariaComponent },
	{ path: 'construccionEdificaciones', component: ConstruccionedificacionesComponent },
	{ path: 'gerenciaproyecto', component: GerenciaproyectoComponent },
	{ path: 'conoceempresa', component: ConoceempresaComponent },
	{ path: 'misionvision', component: MisionvisionComponent },
	{ path: 'organigrama', component: OrganigramaComponent },
	{ path: 'contacto', component: ContactoComponent },
	{ path: 'politica', component: PoliticaComponent },
	{ path: 'compromiso', component: CompromisoComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'home-back', component: HomeBackComponent },
	{ path: '**', redirectTo: 'home' },
];

@NgModule({
	imports:
	[RouterModule.forRoot(routes),
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
	],
	exports: [RouterModule],
	
})

export class AppRoutingModule { }
